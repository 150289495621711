<template>
    <div class="main-panel">
        <!-- BREADCRUMB -->
        <div class="return_button">
            <router-link class="" :to="'/' + entityParent + '/' + idParent">
                <i class="fas fa-arrow-left"></i>
                <span
                    v-if="entityParent === entityType.RESIDENCE"
                    class="mobile"
                >
                    {{ $t("navigation.residenceBack") }}
                </span>
                <span
                    v-else-if="entityParent === entityType.STAIRWELL"
                    class="mobile"
                >
                    {{ $t("navigation.stairwellBack") }}
                </span>
            </router-link>
        </div>

        <!-- INFORMATIONS -->
        <div class="informations-bloc blueType mobile">
            <Infos
                :name="name"
                :formattedFloor="floor"
                :ipass="ipass"
                :comment="comment"
                :nextApartmentId="nextApartmentId"
                :previousApartmentId="previousApartmentId"
                :apartmentPosition="apartmentPosition"
                @nextAppartment="nextApartment"
                @previousAppartment="previousApartment"
                @editAction="editAction"
            />
        </div>

        <hr class="mobile" />

        <!-- TABS -->
        <div class="tabs-bloc">
            <ul class="list-group">
                <!-- Details -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'details' }"
                >
                    <i class="fas fa-city"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.apartmentDetails") }}
                    </span>
                </li>
                <!-- Connect It -->
                <li
                    class="list-group-item"
                    v-if="this.nb_connectit > 0"
                    :class="{ activeTab: activeTab == 'connectit' }"
                    @click="getConnectit()"
                >
                    <i class="fas fa-house-user"></i>
                    <span class="mobile">
                        Connect.it
                        <i
                            v-if="lora.lora_connected === 0"
                            class="fas fa-exclamation-circle manage-warning icon"
                            style="color: #ffbb00"
                        ></i>
                    </span>
                </li>
                <!-- Gestion Locative -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'manage' }"
                    @click="editAction(1)"
                >
                    <i class="fas fa-users"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.manage") }}
                        <i
                            v-if="manage"
                            class="fas fa-check-circle manage-check icon"
                        ></i>
                        <i
                            v-else
                            class="fas fa-times-circle manage-uncheck icon"
                        ></i>
                    </span>
                </li>
                <!-- Liberale -->
                <li
                    v-if="liberale"
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'liberale' }"
                    @click="editAction(1)"
                >
                    <i class="fas fa-user-md"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.liberale") }}
                        <i class="fas fa-check-circle liberale-check icon"></i>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Infos from "./Infos.vue"
import v1mixin from "@/mixins/v1.js"

export default {
    name: "ApartmentLeftMenu",
    components: {
        Infos,
    },
    mixins: [v1mixin],
    props: {
        entityParent: {
            type: String,
            default: "",
        },

        entityType: {
            type: Object,
            default: () => {
                return {}
            },
        },
        name: {
            type: String,
            default: "",
        },
        floor: {
            type: String,
            default: "",
        },
        comment: {
            type: String,
            default: "",
        },
        idParent: {
            type: String,
            default: "",
        },
        ipass: {
            type: Boolean,
            default: false,
        },
        manage: {
            type: Boolean,
            default: false,
        },
        liberale: {
            type: Boolean,
            default: false,
        },
        nb_connectit: {
            type: Number,
            default: 0,
        },
        lora: {
            type: Object,
            default: () => {},
        },
        activeTab: {
            type: String,
            default: "",
        },
        nextApartmentId: {
            type: String,
            default: "",
        },
        previousApartmentId: {
            type: String,
            default: "",
        },
        apartmentPosition: {
            type: String,
            default: "1",
        },
    },
    methods: {
        editAction(value) {
            this.$emit("editAction", value)
        },
        changeTab(tab) {
            this.$emit("updatedActiveTab", tab)
            this.$router.push({
                params: {
                    id: this.$route.params.id,
                    tab: tab,
                },
            })
        },
        loadSubaccounts() {
            this.$emit("loadSubaccounts")
        },
        nextApartment() {
            this.$emit("changeAppart", this.nextApartmentId)
        },
        previousApartment() {
            this.$emit("changeAppart", this.previousApartmentId)
        },
        getConnectit() {
            this.$emit("getConnectit")
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

.icon {
    position: fixed;
}
</style>
